<template>
  <container>
    <div slot="bottom_box_content">
      <component :is="curBottomComponent"></component>
    </div>
  </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// // 引入store模块
import serviceConfigStoreModule from '@/store/modules/serviceConfig'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    BottomComponent: () => import('./components/List.vue')
  },
  created () {
    if (!this.$store.hasModule('serviceConfig')) {
      this.$store.registerModule('serviceConfig', serviceConfigStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setShowMap(false)

    this.setBottomComponent('BottomComponent')
  },
  destroyed () {
    // 卸载reconciliation动态store模块
    this.$store.commit('reset_state')
    this.$store.unregisterModule('serviceConfig')

    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
