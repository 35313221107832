import { getPublisherDeviceModelList } from '@/api/rim/device'
import { getTaskitemType } from '@/api/msp/status'

const getDefaultState = () => {
  return {
    beginUpdateBottom: new Date(),
    beginUpdateLeft: new Date(),
    leftPageType: 'create',
    editId: -1,

    deviceModelListData: [], // 设备类型清单
    taskItemTypesData: []// 任务类型
  }
}
const state = getDefaultState()
const supplierWorkOrder = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_service_config_beginUpdateBottom (state, data) {
      state.beginUpdateBottom = data
    },
    set_service_config_beginUpdateLeft (state, data) {
      state.beginUpdateLeft = data
    },
    set_service_config_leftPageType (state, data) {
      state.leftPageType = data
    },
    set_service_config_editId (state, data) {
      state.editId = data
    },
    set_service_config_deviceModelListData (state, data) {
      state.deviceModelListData = data
    },
    set_service_config_taskItemTypesData (state, data) {
      state.taskItemTypesData = data
    }
  },
  actions: {
    /**
     * 获取当前租户下所有的媒体类型
     * @param {Object} param0
     * @param {Object} query
     */
    getDeviceModelData ({ state, commit }) {
      return new Promise((resolve) => {
        getPublisherDeviceModelList().then(res => {
          if (res && !res.errcode) {
            commit('set_service_config_deviceModelListData', res)
          } else {
            commit('set_service_config_deviceModelListData', [])
          }
          resolve(res)
        })
      })
    },
    getTaskItemTypesData ({ state, commit }) {
      return new Promise((resolve) => {
        getTaskitemType().then(res => {
          if (res && !res.errcode) {
            commit('set_service_config_taskItemTypesData', res)
          } else {
            commit('set_service_config_taskItemTypesData', [])
          }
          resolve(res)
        })
      })
    }
  }
}

export default supplierWorkOrder
